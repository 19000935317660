export default function getProductColor(props) {
  let backGround = ""
  switch (props) {
    case "Himmel": // Bær
      backGround = "bg-himmel-500 bg-opacity-50"
      break
    case "Løk": // Økologisk
      backGround = "bg-lok-500 bg-opacity-30"
      break
    case "Bær": // Frukt - helt sant.
      backGround = "bg-baer-500 bg-opacity-50"
      break
    case "Rosmarin": // Grønnsker
      backGround = "bg-rosmarin-500 bg-opacity-50"
      break
    case "Gulrot": // Poteter
      backGround = "bg-gulrot-500 bg-opacity-50"
      break
    default:
      backGround = "bg-gulrot-500 bg-opacity-50"
      break
  }
  return backGround
}
