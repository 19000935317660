import { graphql } from "gatsby"

import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"

// import { useIsAuthenticated } from "@azure/msal-react"

import SEO, { getSeoProps } from "../components/Common/SEO"

import { RelatedSectionProps, RelatedSection } from "../components/Sections/RelatedSection"

import HeroProductGroups from "../components/Hero/HeroProductGroups"
import getBannerProps from "../components/Hero/getHeroBannerProps"

import getMainPath from "../components/Common/MainPaths"
// import getAuthor from "../components/Common/getAuthor"

/**
 * @description Skaper en side pr produktgruppe
 * @param param0
 */
const ProductGroupTemplate = ({ location, data }) => {
  const mainPath = getMainPath("productgroup")

  const { contentfulProductGroup: content } = data

  let {
    allContentfulProductTeaser: { nodes: allContent },
  } = data

  if (allContent !== null && allContent !== undefined) {
    allContent.sort((a, b) => a.order - b.order)
    if (allContent.length > 3) {
      const size = 3
      allContent = allContent.slice(0, size)
    }
  }

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(content, location, "product")

  // Parse the content, and get the needed banner props
  const heroBannerProps = getBannerProps(content)

  const relatedProps: RelatedSectionProps = {
    title: "Se produkter",
    path: "nb" + mainPath + "/" + content.slug,
    data: allContent,
  }

  return (
    <>
      <SEO {...helmetProps} />
      <main>
        <article>
          <div className="bg-white overflow-hidden  max-w-240 mx-auto ">
            <HeroProductGroups {...heroBannerProps} />
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  mt-24 mb-48">
              <div className="mx-auto max-w-screen md:max-w-2xl pl-4 md:px-8 lg:max-w-3xl xl:max-w-116">
                <MDXRenderer>{content.body.childMdx.body}</MDXRenderer>
              </div>
            </div>
          </div>
        </article>
      </main>
      {allContent.length > 0 ? <RelatedSection {...relatedProps} /> : <></>}
    </>
  )
}

export default ProductGroupTemplate

export const productGroupQuery = graphql`
  query productGroupsQuery($slug: String!) {
    contentfulProductGroup(slug: { eq: $slug }, node_locale: { eq: "nb-NO" }) {
      id
      contentful_id
      slug
      node_locale
      subtitle
      title
      backgroundColor
      metadataDescription
      heroImage {
        id
        public_id
        url
        secure_url
        width
        height
        format
      }
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
      createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
    }
    allContentfulProductTeaser(filter: { node_locale: { eq: "nb-NO" }, productGroup: { slug: { eq: $slug } } }) {
      nodes {
        id
        contentful_id
        metadataDescription
        title
        slug
        node_locale
        videoUrl
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
            }
          }
          url
          secure_url
          width
          height
          format
        }
        createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
        updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
      }
    }
  }
`
