import { HeroTitleProducts } from "../Styling/Titles"
import { Ingress } from "../Styling/Body"
import Product from "../Graphics/Product"
import { BackArrowButton } from "../Graphics/Arrow"
import getMainPath from "../Common/MainPaths"
import getProductColor from "../Styling/getProductColor"

const HeroProductGroups = (props) => {
  const mainPath = getMainPath("productgroup")
  const backGround = getProductColor(props.background)

  return (
    <>
      <header>
        <div className={backGround}>
          <div className="relative max-w-240">
            <div className="mx-auto w-auto xl:max-h-85 grid grid-flow-col grid-cols-1 grid-rows-2 lg:grid-rows-1 lg:grid-cols-2 lg:col-start-2 gap-0 ">
              <div className="relative flex justify-center items-start order-first mx-auto ">
                <div className="relative text-center justify-center break-normal lg:mt-16 ">
                  <div className="hidden items-center  lg:contents z-30">
                    <BackArrowButton url={mainPath} />
                  </div>
                  <div className="mt-14 sm:mt-16">
                    <HeroTitleProducts>{props.title}</HeroTitleProducts>
                  </div>
                  <div className="mt-9 px-4 max-w-xs sm:max-w-sm xl:max-w-lg lg:max-w-md ">
                    {/* <HeroSubtitle>{props.subTitle}</HeroSubtitle>  */}
                    <Ingress>{props.ingress}</Ingress>
                  </div>
                </div>
              </div>
              <div className="relative mx-auto  w-80 sm:w-63 lg:ml-12 lg:max-w-md lg:m-16 2xl:ml-24 xl:max-w-xl my-auto">
                <Product image={props.image} />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default HeroProductGroups
