import { Image, Transformation } from "cloudinary-react"
import { Triangel, triangelProps } from "../Graphics/Triangel"

/**
 * Tar imot et bilde, og returner en div med produkt plassert over triangel
 * @returns
 */
const Product = (image) => {
  const triangel: triangelProps = {
    viewBox: "100 100 220 220",
    className: "fill-current stroke-current stroke-10 text-white opacity-50",
  }
  return (
    <div className="flex items-center align-middle">
      <div className="w-full">
        <Triangel {...triangel} />
      </div>
      <div className="absolute w-full transform -translate-x-6 -translate-y-4">
        <Image
          dpr="auto"
          responsive
          width="auto"
          //   crop="scale"
          //   responsiveUseBreakpoints="true"
          cloudName={process.env.CLOUDINARY_CLOUD_NAME}
          publicId={image.image.imagePublicId}
          alt={image.image.imageAlt}
          secure
        >
          <Transformation crop="fill" fetchFormat="webp" quality="auto" />
        </Image>
      </div>
    </div>
  )
}

export default Product
